//
// SVG
//


// Base
.svg {
    @each $name, $color in $theme-text-colors  {
        &.svg-fill-#{$name} {
            fill: $color !important;
        }

        &.svg-fill-light-#{$name} {
            fill: theme-light-color($name) !important;
        }
    }
}
.payment-icon {
    svg {
        fill: #fff;
    }
}
.switchers {
	input[type=checkbox]{
  height: 0;
  width: 0;
  visibility: hidden;
}

label {
  cursor: pointer;
  text-indent: -9999px;
  width: 60px;
  height: 30px;
  background: grey;
  display: block;
  border-radius: 100px;
  position: relative;
}

label:after {
  content: '';
  position: absolute;
  top: 5px;
  left: 5px;
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 20px;
  transition: 0.3s;
}

input:checked + label {
  background: #5dc6bc;
}

input:checked + label:after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}

label:active:after {
  width: 20px;
}
}